import React from 'react'
import Layout from '../components/layout'

const faqs = [
    {
        id: 1,
        question: "Are the facts factual?",
        answer:
            "They are created by the community, so beware as some might not be completely factual. In a future update though, we will add fact checking moderators.",
    },
    {
        id: 2,
        question: "What is the point of fact moderators?",
        answer:
            "The fact checking moderator is not concerned about the 'Fun' rating, only the proof link that you provide. They will help determine if something can be indepently labeled as factual.",
    },
    {
        id: 3,
        question: "Can I apply to be a fact moderator?",
        answer:
            "Yes! But not until the feature has been rolled out completely.",
    },
    {
        id: 4,
        question: "Can I ask for new features?",
        answer:
            "Absolutely! Please donate and i'll try to add your feature if it seems like a great feature for the community! You can donate from the link in the footer.",
    },
    {
        id: 5,
        question: "Why was this created?",
        answer:
            "Because life is short and facts are fun. So enjoy life and enjoy your fun facts!",
    },
]

const IndexPage = () => {
    return (
        <Layout>
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            Finally, a place for <span className="text-ffpurple-500">fun facts</span>.
                        </h2>
                        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                            The biased and subjective place to get your fun facts rated. No guarantee they are facts, but hopefully they are at least fun!
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
                    <h2 className="text-3xl font-extrabold text-ffpurple-500">Frequently asked questions</h2>
                    <div className="mt-8">
                        <dl className="divide-y divide-gray-200">
                            {faqs.map((faq) => (
                                <div key={faq.id} className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
                                    <dt className="text-base font-medium text-gray-900 md:col-span-5">{faq.question}</dt>
                                    <dd className="mt-2 md:mt-0 md:col-span-7">
                                        <p className="text-base text-gray-500">{faq.answer}</p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
